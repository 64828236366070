/* font -> Roboto */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css or ChatDetail.css */

/* Custom checkbox styling */
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: transparent;
  border: 2px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
  position: relative;
}

/* Checkbox style when checked */
.custom-checkbox:checked {
  background-color: #50c878; /* Green background when checked */
  border-color: #50c878;
}

/* Checkmark style */
.custom-checkbox:checked::after {
  content: "✔";
  font-size: 14px;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* all */
* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

/* html */
html {
  height: 100%;
  overflow: hidden;
}

/* body */
body {
  overflow: auto;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

/* scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* anchor tag */
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

/* media queries */

/* >= 768px */
@media screen and (min-width: 768px) {
  html {
    font-size: 110%;
  }
}
